import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import FooterLogo from './components/shared/footerLogo';
import Kiosk from './components/kiosk';

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route exact path="/:organisationId/sites/:siteId" element={<Kiosk />} />
        </Routes>
      </Router>
      <FooterLogo />
    </>
  );
}

export default App;
